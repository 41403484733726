import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import {
	Battery0BarOutlined,
	Battery20Outlined,
	Battery30Outlined,
	Battery50Outlined,
	Battery60Outlined,
	Battery80Outlined,
	Battery90Outlined,
	BatteryAlertOutlined,
	BatteryFullOutlined,
	BoltOutlined,
	GpsFixedOutlined,
	GpsOffOutlined,
	SignalCellular0BarOutlined,
	SignalCellular1BarOutlined,
	SignalCellular2BarOutlined,
	SignalCellular3BarOutlined,
	SignalCellular4BarOutlined,
	SignalCellularConnectedNoInternet0BarOutlined
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { TMonitoringEvent } from 'app/main/monitoring-event/types';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import formatBattery from 'src/utils/formatBattery';
import { THistoryPosition } from './types';
import { TExcelColumn } from 'app/types/excel-column.types';

export const MAPS_URL = 'https://www.google.com/maps/dir/';

export const splitAndGetOption = <T extends { _id: string, did?: number }>(
	value: string, 
	options: T[], 
	useDid: boolean = false
  ): T[] => {
	const splitted = _.split(value, ',');
	return _.filter(options, (option) => {
	  const key = useDid ? option.did?.toString() : option._id;
	  return splitted.includes(key);
	});
  };

export const getAddress = ({
	ADDRESS_ROAD = '',
	ADDRESS_SUBURB = '',
	ADDRESS_STATE = '',
	ADDRESS_COUNTRY = '',
	ADDRESS_POSTCODE = ''
}) => {
	const road = ADDRESS_ROAD ? `${ADDRESS_ROAD}, ` : '';
	const suburb = ADDRESS_SUBURB ? `${ADDRESS_SUBURB}, ` : '';
	const state = ADDRESS_STATE ? `${ADDRESS_STATE}, ` : '';
	const country = ADDRESS_COUNTRY ? `${ADDRESS_COUNTRY}, ` : '';
	const postcode = ADDRESS_POSTCODE ? `${ADDRESS_POSTCODE} ` : '';

	return `${road}${suburb}${state}${country}${postcode}`;
};
export const getOdometerValue = (odometer: number) =>
	`${(odometer / 1000).toLocaleString('pt-BR', {
		maximumFractionDigits: 2
	})} km`;

export const getSpeedIcon = (speed: number) => (
	<div className="flex items-center gap-8">
		<div className="flex relative w-5 h-14 rounded-full bg-grey">
			<span
				style={{ height: `${speed / 10}px` }}
				className={`flex items-center justify-center w-5 absolute bottom-0 rounded-full
					${speed < 90 ? 'bg-green' : 'bg-yellow'}`}
			/>
		</div>
		<Typography>{speed}</Typography>
	</div>
);

export const getGpsStatusIcon = (isGpsRunning: boolean, gpsFixed: boolean) => (
	<div className="flex items-center gap-8">
		{isGpsRunning ? (
			<FuseSvgIcon color={gpsFixed ? 'success' : 'error'}>
				{`material-outline:${gpsFixed ? 'gps_fixed' : 'gps_not_fixed'}`}
			</FuseSvgIcon>
		) : (
			<FuseSvgIcon>material-outline:gps_off</FuseSvgIcon>
		)}
	</div>
);

export const goToStreetView = (lat: number, lng: number) =>
	window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');

export const RXLEVIcon = ({ RXLEV }: { RXLEV: number }) => {
	if (RXLEV === undefined) return <SignalCellularConnectedNoInternet0BarOutlined fontSize="small" color="error" />;

	if (RXLEV >= 0 && RXLEV <= 10) {
		return <SignalCellular0BarOutlined fontSize="small" />;
	}
	if (RXLEV >= 11 && RXLEV <= 20) {
		return <SignalCellular1BarOutlined fontSize="small" color="error" />;
	}
	if (RXLEV >= 21 && RXLEV <= 30) {
		return <SignalCellular2BarOutlined fontSize="small" color="warning" />;
	}
	if (RXLEV >= 31 && RXLEV <= 40) {
		return <SignalCellular3BarOutlined fontSize="small" color="success" />;
	}
	return <SignalCellular4BarOutlined fontSize="small" color="success" />;
};

export const BatteryIcon = ({ BATTERY }: { BATTERY: number }) => {
	if (BATTERY === undefined) return <BatteryAlertOutlined fontSize="small" color="error" />;

	if (BATTERY >= 0 && BATTERY <= 10) {
		return <Battery0BarOutlined fontSize="small" />;
	}
	if (BATTERY >= 11 && BATTERY <= 20) {
		return <Battery20Outlined fontSize="small" />;
	}
	if (BATTERY >= 21 && BATTERY <= 30) {
		return <Battery30Outlined fontSize="small" />;
	}
	if (BATTERY >= 31 && BATTERY <= 40) {
		return <Battery50Outlined fontSize="small" />;
	}
	if (BATTERY >= 41 && BATTERY <= 50) {
		return <Battery60Outlined fontSize="small" />;
	}
	if (BATTERY >= 51 && BATTERY <= 60) {
		return <Battery80Outlined fontSize="small" />;
	}
	if (BATTERY >= 61 && BATTERY <= 90) {
		return <Battery90Outlined fontSize="small" />;
	}
	return <BatteryFullOutlined fontSize="small" />;
};

export const GPSIcon = ({ GPS_FIXED }: { GPS_FIXED: boolean }) => {
	if (GPS_FIXED) {
		return <GpsFixedOutlined fontSize="small" color="success" />;
	}
	return <GpsOffOutlined fontSize="small" color="error" />;
};

export const IGNITIONIcon = ({ IGNITION_ON }: { IGNITION_ON: boolean }) => {
	if (IGNITION_ON) {
		return <BoltOutlined fontSize="small" color="success" />;
	}
	return <BoltOutlined fontSize="small" color="error" />;
};

export const decodeEventTypeName = (eventType: string, trackerEvents: TMonitoringEvent[], t: TFunction) => {
	const eventCode = _.includes(eventType, 'E.') ? eventType : `E.${eventType}`;

	const findWithCode = _.find(trackerEvents, { eventCode })?.name;
	const findWithEventType = _.find(trackerEvents, {
		eventCode: eventType
	})?.name;

	return findWithCode ?? findWithEventType ?? t('UNKNOWN');
};

export const getExcelColumns = (t: TFunction, trackerEvents: TMonitoringEvent[]): TExcelColumn<THistoryPosition>[] => [
	{
		label: 'ID',
		value: '_id'
	},
	{
		label: t('DID'),
		value: (row) => _.get(row, 'DID', '') + ' - ' + _.get(row, 'tracker.name', '')
	},
	{
		label: t('VEHICLE'),
		value: (row) => _.get(row, 'vehicle.name', '') + ' - ' + _.get(row, 'vehicle.plate', '')
	},
	{
		label: t('PARSER'),
		value: 'PARSER'
	},
	{
		label: t('EVENT_TYPE'),
		value: (row) => decodeEventTypeName(row?.EVENT_TYPE, trackerEvents, t)
	},
	{
		label: t('GPS_TIME'),
		value: (row) => dayjs(row?.GPS_TIME).format('YYYY-MM-DD HH:mm:ss')
	},
	{
		label: t('ADDRESS'),
		value: (row) => getAddress(row)
	},
	{
		label: t('SPEED'),
		value: 'SPEED'
	},
	{
		label: t('SATELLITES'),
		value: 'SATELLITES'
	},
	{
		label: t('RXLEV'),
		value: (row) => (row.RXLEV ? row.RXLEV + 'dbm' : 'N/A')
	},
	{
		label: t('GPS_FIXED'),
		value: (row) => (row?.GPS_FIXED ? t('YES') : t('NO'))
	},
	{
		label: t('IGNITION_ON'),
		value: (row) => (row?.IGNITION_ON ? t('ON') : t('OFF'))
	},
	{
		label: t('BATTERY'),
		value: (row) => formatBattery(row?.BATTERY)
	},
	{
		label: t('ODOMETER'),
		value: (row) => getOdometerValue(row?.MILEAGE)
	},
	{
		label: t('IS_GPS_RUNNING'),
		value: (row) => (row?.IS_GPS_RUNNING ? t('YES') : t('NO'))
	},
	{
		label: t('VEHICLE_VOLTAGE'),
		value: (row) => formatBattery(row?.VEHICLE_VOLTAGE)
	},
	{
		label: t('ALTITUDE'),
		value: (row) => (row?.ALTITUDE ? row?.ALTITUDE + 'm' : 'N/A')
	},
	{
		label: t('LATITUDE'),
		value: (row) => row?.LATITUDE.toFixed(4)
	},
	{
		label: t('LONGITUDE'),
		value: (row) => row?.LONGITUDE.toFixed(4)
	},
	{ label: t('DRIVER'), value: (row) => row?.SERIAL_CODE }
];
