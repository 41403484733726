import { commonPtTranslates } from 'src/constants/common-translates';

const locale = {
	// Columns

	PLATE: 'Placa',
	DID: 'DID',
	ADDRESS: 'Endereço',
	ODOMETER: 'Odômetro',
	GPS_TIME: 'Hora',
	EVENT_TIME: 'Hora',
	SPEED: 'Velocidade',
	IGNITION_ON: 'Ignição',
	IS_GPS_RUNNING: 'GPS',
	GOOGLE_MAPS: 'Google Maps',
	SEQUENCE: 'Sequência',
	PARSER: 'Parser',
	ALTITUDE: 'Altitude',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	HOURS_NUM: '{{n}} Horas',
	UNKNOWN: 'Desconhecido',
	VEHICLE_VOLTAGE: 'Tensão do veículo',
	SERVER_TIME: 'Hora do servidor',

	// Header

	TITLE: 'Relatórios analíticos',
	ADD: 'Adicionar',

	// Table

	NO_HISTORY_FOUND: 'Nenhum evento encontrado',
	NO_FILTERS: 'Nenhum filtro aplicado',
	NO_FILTERS_SUBTITLE: 'Selecione os filtros que deseja para ver os resultados',
	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',

	// Commons

	COLUMNS: 'Colunas',
	INFO_TRACKER: 'Informações do rastreador',
	DETAILS: 'Detalhes',
	COORDINATES: 'Coordenadas',
	RXLEV: 'Intensidade do sinal',
	SATELLITES: 'Satélites',
	GPS_FIXED: 'GPS fixo',
	BATTERY: 'Bateria',
	HOURMETER: 'Horímetro',
	SELECT_FILTERS: 'Selecionar filtros',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MAX_POINTS: 'Numero máximo de pontos exedido, utilizando os 24 primeiros itens',
	SEARCH: 'Pesquisar',
	VIEW_ON_MAP: 'Ver no Google Maps',
	MAX_TIME_INTERVAL: 'O intervalo máximo é de 12 horas',
	POSITION: 'Posição',
	SELECTED_ITEMS: 'Itens selecionados',
	TRACKER: 'Rastreador',
	NO_FILTER_PERMISSION: 'Você não tem permissão para utilizar filtros.',
	NO_FILTER_PERMISSION_DESCRIPTION: 'Entre em contato com o administrador para solicitar as seguintes permissões:',
	VEHICLE: 'Veículo',
	LAT: 'Latitude',
	LNG: 'Longitude',
	ALT: 'Altitude',
	TRIP: 'Jornada nº{{n}}',
	STREET_VIEW_WARNING_1: 'Você selecionou mais de 24 pontos.',
	STREET_VIEW_WARNING_2:
		'As cordenadas foram agrupadas em {{n}} jornadas, ordenadas por data e hora. Você pode selecionar uma jornada para ver os pontos no mapa.',
	TRACKERS: 'Rastreadores',
	FLEETS: 'Frotas',
	VEHICLES: 'Veículos',
	EVENT_TYPE: 'Tipo de evento',
	END_DATE: 'Data final',
	START_DATE: 'Data inicial',
	FLEETS_SELECTED: '{{n}} frotas',
	VEHICLES_SELECTED: '{{n}} veículos',
	TRACKERS_SELECTED: '{{n}} rastreadores',
	EVENT_TYPES_SELECTED: '{{n}} tipos de eventos',
	COMPANIES_SELECTED: '{{n}} empresas',
	APPLY: 'Aplicar',
	RESET: 'Limpar filtros',
	CLOSE: 'Fechar',
	DRIVER: 'Motorista',
	COMPANIES: 'Empresas',
	FILTERS_DESCRIPTION: 'Os campos abaixo, mostrarão os dados apenas das empresas selecionadas.',

	// Validations

	END_REQUIRED: 'Data final é obrigatória',
	START_REQUIRED: 'Data inicial é obrigatória',
	COMPANIES_REQUIRED: 'Pelo menos uma empresa deve ser selecionada',
	AT_LEAST_ONE_FILTER: 'Pelo menos um filtro deve ser selecionado',
	END_DATE_SHALL_BE_GREATER: 'Data final deve ser maior que a data inicial',
	NO_RESULTS: 'Nenhum resultado encontrado',

	// Download

	DOWNLOAD_SELECTED: 'Baixar selecionados',
	DOWNLOAD_ALL: 'Baixar todos',
	DOWNLOAD: 'Baixar',
	DOWNLOAD_WARNING: 'Devido ao grande numero de arquivos, o download pode demorar alguns minutos',
	ERROR_DOWNLOADING_HISTORY: 'Erro ao baixar eventos',
	UNAVAILABLE: 'Indisponível',

	RUNNING: 'Em execução',
	NOT_RUNNING: 'Fora de execução',
	ON: 'Ligada',
	OFF: 'Desligada',
	YES: 'Sim',
	NO: 'Não',

	...commonPtTranslates
};

export default locale;
