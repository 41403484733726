import { commonPtTranslates } from 'src/constants/common-translates';

const pt = {
	TRIPS_ADVANCED: 'Detalhes da viagem',
	DISTANCE: 'Km rodado',
	DURATION: 'Duração',
	MAX_SPEED: 'Velocidade máxima',
	AVG_SPEED: 'Velocidade média',
	START_ADDRESS: 'Endereço inicial',
	END_ADDRESS: 'Endereço final',
	TRACKER_NAME: 'Nome do rastreador',
	COMPANY: 'Empresa',
	PLATE: 'Placa',
	BACK: 'Voltar',
	IGNITION_ON: 'Ignição',
	BATTERY: 'Bateria',
	SPEED: 'Velocidade',
	SHARE: 'Compartilhar',
	UNKNOWN: 'Desconhecido',
	COPIED_TO_CLIPBOARD: 'Copiado para a área de transferência',
	NO_DRIVER: 'Sem motorista.',
	VEHICLE: 'Veículo',
	CENTER: 'Centralizar veículo',
	GEO_FENCE: 'Cercas virtuais',
	FIX_MARKER: 'Seguir veículo',
	VELOCITY: 'Velocidade',

	...commonPtTranslates
};

export default pt;
